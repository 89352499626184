import { createSlice } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
const config = useJwt.jwtConfig
import axios from "axios"

const initialUser = () => {
  const item = window.localStorage.getItem("userData")
  const accessToken = window.localStorage.getItem(config.storageTokenKeyName)

  if (item && accessToken) {
    const userData = JSON.parse(item)
    if (userData.accessToken === accessToken) {
      return userData
    }
  }

  return {}
}

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      const { userData, accessToken, refreshToken } = action.payload
      state.userData = userData
      state[config.storageTokenKeyName] = accessToken
      state[config.storageRefreshTokenKeyName] = refreshToken
      localStorage.setItem(
        `userData_${userData.userId}`,
        JSON.stringify(userData)
      )
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(accessToken)
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(refreshToken)
      )
    },
    handleLogout: (state) => {
      const userId = state.userData.userId
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      localStorage.removeItem(`userData_${userId}`)
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.setItem("logout", true)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
