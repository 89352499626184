// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Config URL
import config from '../../../config'
const environment = process.env.REACT_APP_STAGE || 'development'
const apiUrlUsers = config[environment].apiUrlUsers
const apiUrlRoles = config[environment].apiUrlRoles
import { userRequestsExternal } from '../../../services/UserRequestExternal'

// ** Icons Import
import { BsRecordFill } from "react-icons/bs"

// ** Third Party Components
import _, { orderBy } from 'lodash'

// ** Another requests
import { handleLogout } from '@store/authentication'

// export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
//   const response = await axios.get('/api/users/list/all-data')
//   return response.data
// })

export const getUsers = createAsyncThunk('appUsers/getUsers', async params => {
  try {
    const response = await axios.post(`${apiUrlUsers}/filter`, params)
    return {
      params,
      data: response.data.data.users,
      totalElements: response.data.data.total_rows,
      totalPages: response.data.data.total_pages
    }
  } catch (e) {
    return {
      data: [],
      totalElements: 0,
      totalPages: 0
    }
  }
})

const addIconMenu = (type) => {
  if (type === "roles_users") {
    return <i className="fi fi-rr-user-add"></i>
  } else if (type === "submodules" || type === "modules") {
    return <i className="fi fi-rr-add-document"></i>
  } else {
    return <i className="fi fi-rs-users"></i>
  }
}

const checkIsNewDivision = (date) => {
  if (date) {
    const userDate = new Date(date)
    const threeDaysAgo = new Date()
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3)
    if (userDate > threeDaysAgo) {
      return true
    } else {
      return false
    }

  } else {
    return false
  }

}

export const getUserMenu = createAsyncThunk('appUsers/getUserMenu', async (id, { dispatch }) => {
  try {
    let token = JSON.parse(localStorage.getItem('accessToken'))
    const response = await axios.get(`${apiUrlUsers}/modules`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    let arrayMenuReports = []
    let arrayMenuSystem = []
    let dataConfig = [...response.data.data.config]
    let dataReports = [...response.data.data.modules]

    if (dataConfig.length) {

      dataConfig.forEach(function (item, index) {
        if (item.module === "Administrador de modulos") {
          item.newId = "modules"
          item.module = "Administrador de módulos"
          item.order = 2
        } else if (item.module === "Administrador de submodulos") {
          item.newId = "submodules"
          item.module = "Administrador de submódulos"
          item.order = 3
        } else if (item.module === "Roles y usuarios") {
          item.order = 1
          item.newId = "roles_users"
          item.module = "Roles y usuarios"
        } else if (item.module === "Administracion de clientes") {
          item.newId = "customers"
          item.module = "Administración de clientes"
          item.order = 4
        }

        if (item.module !== "Personalizacion") {
          arrayMenuSystem.push(
            {
              id: item.newId,
              title: item.module,
              icon: addIconMenu(item.newId, item.typeReport),
              children: item.options && item.options.length ? [] : null,
              navLink: item.options && item.options.length ? null : `/${item.newId}`,
              order: item.order,
              permissions: item.options && item.options.length ? null : item.permissions
            }
          )
        }

        if (item.options && item.options.length) {
          item.options.forEach(function (option) {

            arrayMenuSystem[index].children.push(
              {
                id: option.id,
                title: option.name,
                icon: <BsRecordFill size={12} />,
                navLink: `/${item.newId}/${option.id}`,
                order: option.order,
                permissions: item.permissions
              }
            )
          })
        }
      })

      arrayMenuSystem = orderBy(arrayMenuSystem, ['order'], ['asc'])

      arrayMenuSystem.unshift(
        {
          header: 'CONFIGURACIÓN SISTEMA'
        }
      )
    }

    if (dataReports.length) {
      dataReports.forEach(function (item, index) {
        let iconDivision = <i className={item.icon_url}></i>

        arrayMenuReports.push(
          {
            id: item.id,
            title: item.name,
            isNew: checkIsNewDivision(item.create_date),
            icon: iconDivision,
            children: [],
            navLink: item.subModules && item.subModules.length ? null : `/${item.id}/`,
            type: item.type
          }
        )

        if (item.subModules && item.subModules.length) {
          item.subModules.forEach(function (option) {
            let processName = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            processName = processName.replace(/\s/g, '')
            arrayMenuReports[index].children.push(
              {
                id: option.id,
                title: option.name,
                icon: <BsRecordFill size={12} />,
                url_dashboard: option.url_dashboard,
                navLink: `/divisions/${processName}/${option.id}`
              }
            )
          })
        } else {
          arrayMenuReports[index].children.push(
            {
              icon: <BsRecordFill size={12} className="icon-hideMenu" />,
              title: "Sin submódulos",
              disabled: true
            }
          )
        }
      })

      arrayMenuReports.unshift(
        {
          id: "/home",
          title: "Inicio",
          icon: <i className="fi fi-rr-home"></i>,
          navLink: `/home`
        }
      )

      arrayMenuReports.unshift(
        {
          header: 'MÓDULOS'
        }
      )
    } else {
      arrayMenuReports.unshift(
        {
          id: "/home",
          title: "Bienvenida",
          icon: <i className="fi fi-rr-star"></i>,
          children: [
            {
              id: "/home",
              title: "¡Hola, Liderly!",
              icon: <BsRecordFill size={12} />,
              navLink: `/home`
            }
          ],
          navLink: null
        }
      )

      arrayMenuReports.unshift(
        {
          header: 'MÓDULOS'
        }
      )
    }

    let finalArray = [...arrayMenuReports, ...arrayMenuSystem]

    // if (!finalArray.length) {
    //   dispatch(handleLogout())
    // }

    return finalArray

  } catch (e) {
    // dispatch(handleLogout())
    return []
  }

})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  let result = await axios.post(apiUrlUsers, user)
  await dispatch(getUsers(getState().users.params))
  return result
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (params, { dispatch, getState }) => {
  let result = await axios.put(`${apiUrlUsers}/${params.id}`, params.user)
  await dispatch(getUsers(getState().users.params))
  const userLocal = JSON.parse(localStorage.getItem('userData'))
  dispatch(getUserMenu(userLocal.id))
  return result
})


export const updateUserProfile = createAsyncThunk('appUsers/updateUserProfile', async (user, { dispatch, getState }) => {
  if (user.file) {
    await userRequestsExternal.uploadCustomerDefault(user.file, user)
    delete user.file
    delete user.fileId
    let result = await userRequestsExternal.addUserInformation(user.email, user)
    return result
  } else {
    /* if (user.Linkding) {
      await userRequestsExternal.postCustomersSocialMedia(user.email, user)
    } */
    let result = await userRequestsExternal.addUserInformation(user.email, user)
    return result
  }

})

export const updateUserFileCSV = createAsyncThunk('appUsers/updateUserFileCSV', async (user, { dispatch, getState }) => {
  if (user.file) {
    let result = await userRequestsExternal.uploadCustomerResume(user.file, user)
    delete user.file
    delete user.fileId
    return result
  }
})


export const activeUser = createAsyncThunk('appUsers/activeUser', async (id, { dispatch, getState }) => {
  let result = await axios.patch(`${apiUrlUsers}/status/${id}?status=1`)
  await dispatch(getUsers(getState().users.params))
  return result
})

export const deactiveUser = createAsyncThunk('appUsers/deactiveUser', async (id, { dispatch, getState }) => {
  let result = await axios.patch(`${apiUrlUsers}/status/${id}?status=0`)
  await dispatch(getUsers(getState().users.params))
  return result
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  let result = await axios.delete(`${apiUrlUsers}/${id}`)
  await dispatch(getUsers(getState().users.params))
  return result
})

export const getUserById = createAsyncThunk('appUsers/getUserById', async (id) => {
  try {
    const response = await axios.get(`${apiUrlUsers}/${id}`)
    return response.data.data[0]

  } catch (e) {
    return {
      data: []
    }
  }

})

export const getUserByEmail = createAsyncThunk('appUsers/getUserByEmail', async (email) => {
  try {
    let params = {
      search: email,
      page: 0,
      limit: 1
    }

    const response = await axios.post(`${apiUrlUsers}/filter`, params)
    return response.data.data.users[0]

  } catch (e) {
    return {}
  }

})

export const getRolesForUsers = createAsyncThunk('roles/getRolesForUsers', async () => {
  try {
    const response = await axios.get(apiUrlRoles)
    response.data.data.forEach(function (item) {
      item.value = item.id
      item.label = item.name
    })

    return response.data.data
  } catch (e) {
    return {
      data: []
    }
  }

})

export const getRolesFromFilter = createAsyncThunk('roles/getRolesFromFilter', async () => {
  try {
    const response = await axios.get(apiUrlRoles)
    response.data.data.forEach(function (item) {
      item.value = item.id
      item.label = item.name
    })

    response.data.data.unshift(
      { value: 0, label: 'Seleccionar rol' }
    )
    return response.data.data
  } catch (e) {
    return {
      data: []
    }
  }

})

export const getCustomers = createAsyncThunk('appUsers/getCustomers', async params => {
  try {
    const response = await axios.post(`${apiUrlUsers}/client/filter`, params)
    return {
      params,
      data: response.data.data.clients,
      totalElements: response.data.data.total_rows,
      totalPages: response.data.data.total_pages
    }
  } catch (e) {
    return {
      data: [],
      totalElements: 0,
      totalPages: 0
    }
  }
})

export const addCustomer = createAsyncThunk('appUsers/addCustomer', async (user, { dispatch, getState }) => {
  let result = await axios.post(`${apiUrlUsers}/client`, user)
  await dispatch(getCustomers(getState().users.params))
  return result
})


export const getCustomerById = createAsyncThunk('appUsers/getCustomerById', async (id) => {
  try {
    const response = await axios.get(`${apiUrlUsers}/client/${id}`)
    return response.data.data[0]

  } catch (e) {
    return {
      data: []
    }
  }

})

export const updateCustomer = createAsyncThunk('appUsers/updateCustomer', async (params, { dispatch, getState }) => {
  let result = await axios.put(`${apiUrlUsers}/client/${params.id}`, params.customer)
  await dispatch(getCustomers(getState().users.params))
  return result
})

export const activeCustomer = createAsyncThunk('appUsers/activeCustomer', async (id, { dispatch, getState }) => {
  let result = await axios.patch(`${apiUrlUsers}/client/status/${id}?status=1`)
  await dispatch(getCustomers(getState().users.params))
  return result
})

export const deactiveCustomer = createAsyncThunk('appUsers/deactiveCustomer', async (id, { dispatch, getState }) => {
  let result = await axios.patch(`${apiUrlUsers}/client/status/${id}?status=0`)
  await dispatch(getCustomers(getState().users.params))
  return result
})

export const addPermissionsCustomer = createAsyncThunk('appUsers/addPermissionsCustomer', async (params, { dispatch, getState }) => {
  let result = await axios.put(`${apiUrlUsers}/client/permissions/${params.id}`, params.permissions)
  await dispatch(getCustomers(getState().users.params))
  return result
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    users: null,
    totalElements: 0,
    totalPages: 0,
    params: {},
    allData: [],
    selectedUser: null,
    totalUsers: 0,
    activeUsers: 0,
    inactiveUsers: 0,
    roles: [],
    rolesFromFilter: [],
    menu: [],
    customers: null,
    openChatbot: false,
    menuHover: false,
    updatePreferences: false
  },
  reducers: {
    handleOpenChatbot: (state, action) => {
      state.openChatbot = action.payload
    },
    handleMenuHover: (state, action) => {
      state.menuHover = action.payload
    },
    handleUpdatePreferences: (state, action) => {
      state.updatePreferences = action.payload
    }
  },
  extraReducers: builder => {
    builder
      // .addCase(getAllData.fulfilled, (state, action) => {
      //   state.allData = action.payload
      // })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data
        state.params = action.payload.params
        state.totalElements = action.payload.totalElements
        state.totalPages = action.payload.totalPages
      })
      .addCase(getRolesForUsers.fulfilled, (state, action) => {
        state.roles = action.payload
      })
      .addCase(getRolesFromFilter.fulfilled, (state, action) => {
        state.rolesFromFilter = action.payload
      })
      .addCase(getUserMenu.fulfilled, (state, action) => {
        state.menu = action.payload
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.data
        state.params = action.payload.params
        state.totalElements = action.payload.totalElements
        state.totalPages = action.payload.totalPages
      })
  }
})

export const {
  handleOpenChatbot,
  handleMenuHover,
  handleUpdatePreferences
} = appUsersSlice.actions

export default appUsersSlice.reducer
