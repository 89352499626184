// ** Logo
import logoComplete from '@src/assets/images/logo/logoSplash.svg'

// ** Reactstrap
import { Spinner } from "reactstrap"

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
      <Spinner size="sm" className='spinner-component'/>
      <img className='fallback-logo' src={logoComplete} alt='logoComplete' />

    </div>
  )
}

export default SpinnerComponent
