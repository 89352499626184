
const configExternalApi = {
    azure_dev: {
        apiUrlUsersExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customer-details",
        apiUrlImagesDefaultExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-default-profile-images",
        apiUrlCustomerImagesExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-profile-image",
        apiUrlProfessionalExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/professional-information",
        apiUrlAchievementsExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/achievements-customer",
        apiUrlCompanyJobsExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/company-information",
        apiUrlApplicationsJobsExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-jobs-applications",
        apiUrlCoursesExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/client-information-courses",
        apiUrlCoursesProgressExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-courses-progress",
        apiUrlRoutesProgressExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-learning-paths-progress",
        apiUrlMentoringExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/client-information-mentoring",
        apiUrlUserMonthlyMentoring: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/clients-information-monthly-mentoring",
        apiUrlMentorInformationExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/mentor-information",
        apiUrlHobbiesExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customer-hobbies",
        apiUrlJobsExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/client-information-jobs",
        apiUrlPreferencesExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customer-preferences",
        apiUrlAuthExternal: "https://apazmanagerdev01.azure-api.net/omnisys-uid/validar-token",
        urlLogout: "https://apiaddev.orangeforest-62ac9073.eastus.azurecontainerapps.io/logout?id=lumston",
        urlLogin: "https://apiaddev.orangeforest-62ac9073.eastus.azurecontainerapps.io/login?id=lumston",
        apiUrlProfessionalInformationAcademic: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/professional-information-academic",
        apiUrlProfessionalInformationJob: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/professional-information-job",
        apiUrlCustomerResumeExternal: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-resume",
        headerAuth: "47ad372ccd7d4786b884d894da92d94a",
        headerProfileBack: "4cb4e000fde34aafb2f19e8c1b6fc252",
        headerProfileBackB: '4b96dbfe6fdc421a841eefdbe487cb0b',
        //apiUrlNewLiderly:  "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back",
        //apiUrlNewLiderly:  "https://liderlyperfilbackdev.whitecliff-82345062.eastus.azurecontainerapps.io",
        //apiUrlCustomersSocialMedia: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-social-media",
        //apiUrlNewLiderly:  "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back",
        apiUrlNewLiderly: "https://liderlyperfilbackdev.whitecliff-82345062.eastus.azurecontainerapps.io",
        apiUrlCustomersSocialMedia: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-social-media",
        apiProfileBackIntegrations: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back",
        apiProfileBackIntegrationsB: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b",
        apiSigupMentor: "https://liderlyperfilbackdev.whitecliff-82345062.eastus.azurecontainerapps.io/customers/mentor/onboarding:request",
        apiUrlPersonalInformation: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customer-details",
        apiUrlCoursePromote: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/learning-hub-recommended-products",
        apiUrlForm: "https://dev.liderly.com/poc-buyer/simulador-buyer.html",
        apiUrlMostRatedHour: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentee-schedule-time",
        apiUrlStudentAsigned: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-assigned-mentees",
        apiUrlStudentMentoringMonthly: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/clients-information-monthly-mentoring",
        apiUrlAsignedMentor: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-assigned",
        apiUrlMentorRating: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-rating-area",
        apiUrlMentorEcosystem: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-ecosystem",
        apiUrlMentorAssignedMenteesStats: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-assigned-mentees-stats",
        apiUrlEcosystemAccompanying: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-ecosystem-accompanying',
        apiUrlMentorAssigned: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentor-assigned',
        apiUrlMenteeRatingScores: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentee-rating-scores',
        menteeRanquingAll: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/mentee-rating-all",
        apiUrlCardBlue: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/employments-team",
        apiLearnersTraining: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/learning-assigned",
        apiUrlEmploymentsMetrics: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/employments-metrics',
        apiUrlEmploymentsCompanyJobsPostsStats: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/employments-company-jobs-posts-stats',
        apiUrlCompaniesProductsStats: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/companies-products-stats',
        apiUrlCompaniesProductsPurchased: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/companies-products-purchased',
        apiUrlCustomersInProgressProductsMetrics: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-in-progress-products-metrics',
        apiUrlLearningHubTopProducts: "https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/learning-hub-top-products",
        
        apiUrlEmploymentsSkillsJobsRated: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/employments-skills-jobs-rated',
        apiUrlCustomersCoursesFinalization: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-courses-finalization',
        apiUrlStudentIssuedBadges: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/student-issued-badges',
        apiUrlCustomersJobsRecommended: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-jobs-recommended',
        apiUrlClientsInformationMentoringMentorsHistorical: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/clients-information-mentoring-mentors-historical',
        apiUrlCustomersPreferencesWidgets: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/customers-preferences-widgets',
        apiUrlLookupWidgets: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/lookup-widgets',
        apiUrlEmploymentsCompanyJobsPosts: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/employments-company-jobs-posts',
        apiUrlEmploymentsCompanyJobsProspects: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back-b/employments-company-jobs-prospects',
        apiUrlEmploymentsCompanyJobsProspectsV2: 'https://apazmanagerdev01.azure-api.net/omnisys-perfil-back/companies-jobs-prospects-v2',
        apiOmnisysIntegracion: 'https://apazmanagerdev01.azure-api.net/omnisys-integracion',
        headerOmnisysIntegracion: '8f72fa6a7fb242739749b373ffa428ea',
        headerOmnisysIntegracionB: 'd3dae5e5cf3c4915afeb80b57024de16',
        storeLink: 'https://tienda.liderly.com/collections/all'
    },
    azure_qa: {
        apiUrlUsersExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customer-details",
        apiUrlImagesDefaultExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-default-profile-images",
        apiUrlCustomerImagesExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-profile-image",
        apiUrlProfessionalExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/professional-information",
        apiUrlAchievementsExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/achievements-customer",
        apiUrlCompanyJobsExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/company-information",
        apiUrlApplicationsJobsExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-jobs-applications",
        apiUrlCoursesExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/client-information-courses",
        apiUrlCoursesProgressExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-courses-progress",
        apiUrlRoutesProgressExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-learning-paths-progress",
        apiUrlMentoringExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/client-information-mentoring",
        apiUrlUserMonthlyMentoring: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/clients-information-monthly-mentoring",
        apiUrlMentorInformationExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/mentor-information",
        apiUrlHobbiesExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customer-hobbies",
        apiUrlJobsExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/client-information-jobs",
        apiUrlPreferencesExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customer-preferences",
        apiUrlAuthExternal: "https://apazmanagerqa01.azure-api.net/omnisys-uid-qa/validar-token",
        urlLogout: "https://apiadqa.wittypebble-e426d6e2.eastus.azurecontainerapps.io/logout?id=lumston",
        urlLogin: "https://apiadqa.wittypebble-e426d6e2.eastus.azurecontainerapps.io/login?id=lumston",
        apiUrlProfessionalInformationAcademic: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/professional-information-academic",
        apiUrlProfessionalInformationJob: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/professional-information-job",
        apiUrlCustomerResumeExternal: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-resume",
        headerAuth: "c91f904676d743158373eeaae31a1081",
        headerProfileBack: "60ef4a85b063431eac459a5411a72ece",
        headerProfileBackB: '6a0c57cdcf6c4474813736808f31b7e9',
        apiUrlNewLiderly: "https://perfilbackqa.kindbush-896a0b71.eastus.azurecontainerapps.io",
        apiUrlCustomersSocialMedia: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-social-media",
        apiProfileBackIntegrations: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa",
        apiProfileBackIntegrationsB: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b",
        apiSigupMentor: "https://perfilbackqa.kindbush-896a0b71.eastus.azurecontainerapps.io/customers/mentor/onboarding:request",
        apiUrlPersonalInformation: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customer-details",
        apiUrlCoursePromote: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/learning-hub-recommended-products",
        apiUrlForm: "https://qa.liderly.com/poc-buyer/simulador-buyer.html",
        apiUrlMostRatedHour: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentee-schedule-time",
        apiUrlStudentAsigned: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-assigned-mentees",
        apiUrlStudentMentoringMonthly: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/clients-information-monthly-mentoring",
        apiUrlAsignedMentor: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-assigned",
        apiUrlMentorRating: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-rating-area",
        apiUrlMentorEcosystem: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-ecosystem",
        apiUrlMentorAssignedMenteesStats: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-assigned-mentees-stats",
        apiUrlEcosystemAccompanying: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-ecosystem-accompanying',
        apiUrlMentorAssigned: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentor-assigned',
        apiUrlMenteeRatingScores: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentee-rating-scores',
        menteeRanquingAll: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/mentee-rating-all",
        apiUrlCardBlue: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/employments-team",
        apiLearnersTraining: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/learning-assigned",
        apiUrlEmploymentsMetrics: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/employments-metrics',
        apiUrlEmploymentsCompanyJobsPostsStats: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/employments-company-jobs-posts-stats',
        apiUrlCompaniesProductsStats: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/companies-products-stats',
        apiUrlCompaniesProductsPurchased: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/companies-products-purchased',
        apiUrlCustomersInProgressProductsMetrics: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-in-progress-products-metrics',
        apiUrlLearningHubTopProducts: "https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/learning-hub-top-products",
        
        apiUrlEmploymentsSkillsJobsRated: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/employments-skills-jobs-rated',
        apiUrlCustomersCoursesFinalization: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-courses-finalization',
        apiUrlStudentIssuedBadges: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/student-issued-badges',
        apiUrlCustomersJobsRecommended: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-jobs-recommended',
        apiUrlClientsInformationMentoringMentorsHistorical: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/clients-information-mentoring-mentors-historical',
        apiUrlCustomersPreferencesWidgets: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/customers-preferences-widgets',
        apiUrlLookupWidgets: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/lookup-widgets',
        apiUrlEmploymentsCompanyJobsPosts: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/employments-company-jobs-posts',
        apiUrlEmploymentsCompanyJobsProspects: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa-b/employments-company-jobs-prospects',
        apiUrlEmploymentsCompanyJobsProspectsV2: 'https://apazmanagerqa01.azure-api.net/omnisys-perfil-back-qa/companies-jobs-prospects-v2',
        apiOmnisysIntegracion: 'https://apazmanagerqa01.azure-api.net/omnisys-integracion-qa',
        headerOmnisysIntegracion: '29481b10bc3e4c40a92ce624627a3cc8',
        headerOmnisysIntegracionB: 'd3dae5e5cf3c4915afeb80b57024de16',
        storeLink: 'https://tienda.liderly.com/collections/all'
    },
    azure_prod: {
        apiUrlUsersExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customer-details",
        apiUrlImagesDefaultExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-default-profile-images",
        apiUrlCustomerImagesExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-profile-image",
        apiUrlProfessionalExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/professional-information",
        apiUrlAchievementsExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/achievements-customer",
        apiUrlCompanyJobsExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/company-information",
        apiUrlApplicationsJobsExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-jobs-applications",
        apiUrlCoursesExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/client-information-courses",
        apiUrlCoursesProgressExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-courses-progress",
        apiUrlRoutesProgressExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-learning-paths-progress",
        apiUrlMentoringExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/client-information-mentoring",
        apiUrlUserMonthlyMentoring: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/clients-information-monthly-mentoring",
        apiUrlMentorInformationExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/mentor-information",
        apiUrlHobbiesExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customer-hobbies",
        apiUrlJobsExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/client-information-jobs",
        apiUrlPreferencesExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customer-preferences",
        apiUrlAuthExternal: "https://apazmanagerprod01.azure-api.net/omnisys-uid-prod/validar-token",
        urlLogout: "https://apiadprod.greensmoke-aae4d958.eastus.azurecontainerapps.io/logout?id=lumston",
        urlLogin: "https://apiadprod.greensmoke-aae4d958.eastus.azurecontainerapps.io/login?id=lumston",
        apiUrlProfessionalInformationAcademic: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/professional-information-academic",
        apiUrlProfessionalInformationJob: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/professional-information-job",
        apiUrlCustomerResumeExternal: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-resume",
        headerAuth: "9ca124ae75aa4df1a55e199789ff854d",
        headerProfileBack: "5534d3fe823645c5b1e560e6abe41520",
        headerProfileBackB: 'd3dae5e5cf3c4915afeb80b57024de16',
        apiUrlNewLiderly: "https://perfilback-prod.greensand-12764bce.eastus.azurecontainerapps.io",
        apiUrlCustomersSocialMedia: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-social-media",
        apiProfileBackIntegrations: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod",
        apiProfileBackIntegrationsB: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b",
        apiSigupMentor: "https://perfilback-prod.greensand-12764bce.eastus.azurecontainerapps.io/customers/mentor/onboarding:request",
        apiUrlPersonalInformation: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customer-details",
        apiUrlCoursePromote: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/learning-hub-recommended-products",
        apiUrlForm: "https://www.liderly.com/poc-buyer/simulador-buyer.html",
        apiUrlMostRatedHour: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentee-schedule-time",
        apiUrlStudentAsigned: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-assigned-mentees",
        apiUrlStudentMentoringMonthly: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/clients-information-monthly-mentoring",
        apiUrlAsignedMentor: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-assigned",
        apiUrlMentorRating: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-rating-area",
        apiUrlMentorEcosystem: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-ecosystem",
        apiUrlMentorAssignedMenteesStats: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-assigned-mentees-stats",
        apiUrlEcosystemAccompanying: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-ecosystem-accompanying',
        apiUrlMentorAssigned: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentor-assigned',
        apiUrlMenteeRatingScores: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentee-rating-scores',
        menteeRanquingAll: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/mentee-rating-all",
        apiUrlCardBlue: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/employments-team",
        apiLearnersTraining: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/learning-assigned",
        apiUrlEmploymentsMetrics: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/employments-metrics',
        apiUrlEmploymentsCompanyJobsPostsStats: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/employments-company-jobs-posts-stats',
        apiUrlCompaniesProductsStats: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/companies-products-stats',
        apiUrlCompaniesProductsPurchased: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/companies-products-purchased',
        apiUrlCustomersInProgressProductsMetrics: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-in-progress-products-metrics',
        apiUrlLearningHubTopProducts: "https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/learning-hub-top-products",
        
        apiUrlEmploymentsSkillsJobsRated: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/employments-skills-jobs-rated',
        apiUrlCustomersCoursesFinalization: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-courses-finalization',
        apiUrlStudentIssuedBadges: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/student-issued-badges',
        apiUrlCustomersJobsRecommended: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-jobs-recommended',
        apiUrlClientsInformationMentoringMentorsHistorical: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/clients-information-mentoring-mentors-historical',
        apiUrlCustomersPreferencesWidgets: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/customers-preferences-widgets',
        apiUrlLookupWidgets: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/lookup-widgets',
        apiUrlEmploymentsCompanyJobsPosts: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/employments-company-jobs-posts',
        apiUrlEmploymentsCompanyJobsProspects: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod-b/employments-company-jobs-prospects',
        apiUrlEmploymentsCompanyJobsProspectsV2: 'https://apazmanagerprod01.azure-api.net/omnisys-perfil-back-prod/companies-jobs-prospects-v2',
        apiOmnisysIntegracion: 'https://apazmanagerprod01.azure-api.net/omnisys-integracion-prod',
        headerOmnisysIntegracion: '309abca19e8f4b19a38e517852e2f47e',
        storeLink: 'https://tienda.liderly.com/collections/all'
    }
}

export default configExternalApi
